
import {
    computed, defineComponent, PropType, ref, watch
} from 'vue';
import { SelectItem } from './all.type';

export default defineComponent({
    props: {
        list: {
            type: Array as PropType< Array<SelectItem> >,
            required: true
        },
        active: {
            type: String,
            default: ''
        },
        filterable: {
            type: Boolean,
            default: false
        },
        clearFilter: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const filterValue = ref('');
        const filteredList = computed(() => {
            if (filterValue.value) {
                return props.list.filter((item) => item.label.toLowerCase().includes(filterValue.value.toLowerCase()));
            }
            return props.list;
        });

        watch(() => props.clearFilter, () => {
            if (props.clearFilter) {
                filterValue.value = '';
            }
        });

        const choose = (value: SelectItem) => {
            emit('changeSelect', value.key);
        };

        return {
            filterValue,
            filteredList,
            choose
        };
    }
});
